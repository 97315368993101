<template>
  <div>
    <v-tabs
      v-model="openProcessIndex"
      class="border-b"
      show-arrows
      icons-and-text
      grow
      @change="removeProcessIdQueryParam"
    >
      <v-tab v-for="p in orderPart.processes" class="px-3" :key="p.id" :ripple="false">
        <div v-if="order" class="caption">
          {{ order.order_no }}.{{ orderPart.order_part_no }}.{{ p.order }}
        </div>
        <div>
          {{ p.process.title }}
        </div>
        <div :class="processStatuses[p.status].color" class="status-bubble">
          <v-icon v-if="canEditProcess(p)" small :color="processStatuses[p.status].iconColor">
            mdi-account-check
          </v-icon>
        </div>
      </v-tab>
    </v-tabs>

    <template v-if="openProcess">
      <v-row class="pt-1 px-3" no-gutters>
        <v-col class="py-2" cols="12" md="4">
          <div class="subtitle-2">
            {{ $t('quantity_produced_in_units') }}
          </div>
          <v-menu
            v-if="openProcess.quantity && canEditProcess(openProcess)"
            :close-on-content-click="false"
            offset-y
            nudge-bottom="8"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" class="link">
                {{ openProcess.quantity }}/{{ openProcess.planned_quantity }}
              </div>
            </template>
            <ProcessCard
              :key="openProcess.id"
              :process-id="openProcess.process_id"
              :order-part-process-id="openProcess.id"
              :displayed-tabs="['progress']"
            />
          </v-menu>
          <div v-else class="black--text">
            {{ openProcess.quantity }}/{{ openProcess.planned_quantity }}
          </div>
        </v-col>
        <v-col class="py-2" order="2" cols="12" md="4">
          <div class="subtitle-2">
            {{ $t('process_status') }}
          </div>
          <div class="d-flex align-center">
            <OrderPartProcessStatusChip :process="openProcess" />
            <span v-if="isProcessBeingDoneByAnotherEmployee" class="ml-1">
              ({{ $t('another_employee_is_working').toLowerCase() }})
            </span>
          </div>
        </v-col>
        <v-col
          v-if="openProcessStatus !== 'canceled'"
          cols="12"
          md="4"
          :order="$vuetify.breakpoint.smAndDown ? 'first' : 'last'"
          class="d-flex justify-end flex-column py-2 pr-0 pr-md-4"
        >
          <v-btn
            v-if="canEditProcess(openProcess) && openProcessStatus !== 'completed'"
            :disabled="isProcessUpdating"
            :loading="isProcessUpdating"
            :color="processStatuses[openProcessStatus].buttonColor || 'primary'"
            block
            large
            @click="updateProcessStatus({ ...openProcess, status: openProcessStatus })"
          >
            <v-icon v-if="openProcessStatus === 'in_progress'" left>mdi-check</v-icon>
            {{ $t(`process_actions.${processStatuses[openProcessStatus].buttonText}`) }}
          </v-btn>

          <v-btn
            v-if="canEditProcess(openProcess) && openProcessStatus === 'in_progress'"
            :disabled="isProcessUpdating"
            :loading="isProcessUpdating"
            class="mt-4"
            color="error"
            block
            large
            @click="
              updateProcessStatus({ ...openProcess, status: openProcessStatus }, 'not_started')
            "
          >
            <v-icon left>mdi-pause</v-icon>
            {{ $t('process_actions.pause_process') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="px-3" no-gutters>
        <v-col class="py-2" cols="12" sm="6" md="4">
          <div class="subtitle-2">
            {{ $t('estimated_start_at') }}
          </div>
          <div class="black--text">
            {{ openProcess.estimated_start_at ? openProcess.estimated_start_at.slice(0, -3) : '-' }}
          </div>
        </v-col>

        <v-col class="py-2" cols="12" sm="6" md="4">
          <div class="subtitle-2">{{ $t('estimated_duration') }}</div>
          <div class="black--text">
            {{ durationLabel }}
          </div>
        </v-col>

        <v-col class="d-flex justify-end py-2 pr-0 pr-md-4" cols="12" md="4">
          <v-btn
            v-if="
              canEditProcess(openProcess) &&
                openProcessStatus !== 'not_started' &&
                openProcessStatus !== 'completed'
            "
            color="primary"
            block
            large
            @click="editProcessProgress(openProcess)"
          >
            {{ $t('enter_day_result') }}
          </v-btn>
        </v-col>

        <v-col v-if="openProcess.comment" class="py-2" cols="12">
          <v-alert
            color="red"
            :max-width="$vuetify.breakpoint.lgAndUp ? '35em' : ''"
            class="title text-preserve-whitespace"
            type="info"
            text
          >
            <div>{{ $t('comment') }}:</div>
            <div class="font-weight-regular">{{ openProcess.comment }}</div>
          </v-alert>
        </v-col>
      </v-row>

      <v-divider />

      <WorkerProcessMachinery
        :key="openProcess.id"
        :process="openProcess"
        :can-edit="canEditProcess(openProcess)"
      />

      <v-divider />

      <v-row no-gutters class="px-3">
        <v-col class="py-2" cols="12">
          <MeasurementTable
            :key="openProcess.id"
            :order-part-process-id="openProcess.id"
            :can-fill-in-measures="canEditProcess(openProcess)"
            worker-view
          />
        </v-col>
      </v-row>

      <div v-if="canEditProcess(openProcess)">
        <ProcessProgressFormDialog
          :key="openProcess.id"
          :order-part="orderPart"
          :order-part-process="openProcess"
          :completing-process="completingProcess"
          @update:location="$emit('update:location', $event)"
          @update:process="onProcessUpdate"
        />
      </div>

      <MeasurementsFormDialog worker-view />
    </template>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { addSeconds, formatDistanceToNow } from 'date-fns';
import { lt } from 'date-fns/locale';
import orderPartService, { getOrderPartProcessStatusMap } from '../api/order-part-service';
import formMixin from '../mixins/form-mixin';
import eventBus, { OPEN_SNACKBAR, openConfirmDialog } from '../util/event-bus';
import MeasurementTable from './tables/MeasurementTable';
import MeasurementsFormDialog from './forms/MeasurementsFormDialog';
import WorkerProcessMachinery from './WorkerProcessMachinery';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../store/modules/dialogs';
import ProcessProgressFormDialog from './ProcessProgressFormDialog';
import OrderPartProcessStatusChip from '@/components/OrderPartProcessStatusChip';
import clone from 'just-clone';
import { mapActions, mapGetters } from 'vuex';
import ProcessCard from '@/components/ProcessCard';

export default {
  name: 'WorkerProcesses',

  components: {
    ProcessCard,
    OrderPartProcessStatusChip,
    ProcessProgressFormDialog,
    WorkerProcessMachinery,
    MeasurementsFormDialog,
    MeasurementTable,
  },

  mixins: [formMixin],

  props: {
    orderPart: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      default: () => null,
    },
    openProcessId: {
      // only for opening a process externally from WorkerOrderPart
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      openProcessIndex: 0,
      processStatuses: getOrderPartProcessStatusMap(),
      isProcessUpdating: false,
      newWarehouseLocationId: null,
      completingProcess: false,
    };
  },

  computed: {
    ...mapGetters('orderPartProcesses', ['currentUserUnfinishedProcessesMap']),

    openProcess() {
      return this.orderPart.processes[this.openProcessIndex];
    },

    openProcessStatus() {
      if (
        this.currentUserUnfinishedProcessesMap[this.openProcess.id] ||
        ['cancelled', 'completed'].includes(this.openProcess.status)
      ) {
        return this.openProcess.status;
      }
      return 'not_started';
    },

    isProcessBeingDoneByAnotherEmployee() {
      return (
        this.openProcess.status === 'in_progress' &&
        !this.currentUserUnfinishedProcessesMap[this.openProcess.id]
      );
    },

    durationLabel() {
      // Initial requirements were to have estimated duration in minutes, but later changed
      // to seconds. Backend field name wasn't updated
      const seconds = +this.openProcess.estimated_duration_in_minutes;
      const quantity = +this.openProcess.planned_quantity || 1;

      if (!seconds) {
        return '-';
      }

      const totalSeconds = seconds * quantity;
      return formatDistanceToNow(addSeconds(new Date(), totalSeconds), {
        locale: lt,
      });
    },
  },

  watch: {
    openProcessId: {
      handler(newValue) {
        if (!newValue) {
          return;
        }
        for (let i = 0; i < this.orderPart.processes.length; i++) {
          if (this.orderPart.processes[i].id === newValue) {
            this.openProcessIndex = i;
            if (
              window.kagneta_attemptedToStartOrderPartProcess?.id === newValue &&
              this.openProcessStatus === 'not_started'
            ) {
              window.kagneta_attemptedToStartOrderPartProcess = null;
              this.updateProcessStatus({ ...this.openProcess, status: this.openProcessStatus });
            }
            break;
          }
        }
      },
      immediate: true,
    },

    openProcessIndex: {
      // fires every time an order part or order part process is changed
      handler(newValue) {
        if (this.orderPart.processes[newValue]) {
          this.$emit('change:open-process', this.orderPart.processes[newValue]);
        }
        this.fetchCurrentUnfinishedProcesses();
        this.fetchOrderPart(this.orderPart.id);
      },
      immediate: true,
    },
  },

  created() {
    for (let i = 0; i < this.orderPart.processes.length; i++) {
      const process = this.orderPart.processes[i];
      // Set open process to the first process that the current user is assigned to
      if (
        this.$store.getters.currentUserProcessIds.has(process.process_id) &&
        process.status !== 'completed' &&
        process.status !== 'canceled'
      ) {
        this.openProcessIndex = i;
        break;
      }
    }
  },

  methods: {
    ...mapActions('orderPartProcesses', ['fetchCurrentUnfinishedProcesses']),
    ...mapActions('orderParts', ['fetchOrderPart']),

    canEditProcess(process) {
      return this.$store.getters.currentUserProcessIds.has(process.process_id);
    },

    openNextProcess() {
      if (this.openProcessIndex < this.orderPart.processes.length - 1) {
        this.openProcessIndex++;
      }
    },

    editProcessProgress() {
      this.completingProcess = false;
      this.$store.commit(OPEN_DIALOG, 'processProgressForm');
    },

    updateProcessStatus(process, status = '') {
      const newStatus = status || this.processStatuses[process.status].nextStatus;
      this.newWarehouseLocationId = this.orderPart.warehouse_location_id;
      if (newStatus === 'completed') {
        this.completingProcess = true;
        this.$store.commit(OPEN_DIALOG, 'processProgressForm');
      } else {
        const updatedProcess = {
          ...process,
          status: newStatus,
        };
        this.saveProcess(updatedProcess);
      }
    },

    onProcessUpdate(process) {
      this.$emit('update:process', process);
      if (process.status === 'completed') {
        this.openNextProcess();
      }
    },

    // Updates the process with new quantity and status.
    // Also updates orderPart warehouse_location_id if it has changed.
    // (User is prompted to enter it when completing a process)
    async saveProcess(process) {
      this.isProcessUpdating = true;
      let updatedProcess = clone(process);

      const promises = [
        orderPartService.updateProcess(process).then(res => {
          for (let i = 0; i < res.data.processes.length; i++) {
            const p = res.data.processes[i];
            if (p.id === process.id) {
              updatedProcess = p;
              break;
            }
          }
        }),
      ];
      if (this.newWarehouseLocationId !== this.orderPart.warehouse_location_id) {
        const orderPartPayload = {
          id: this.orderPart.id,
          warehouse_location_id: this.newWarehouseLocationId,
        };
        promises.push(orderPartService.updateLocation(orderPartPayload));
      }

      await Promise.all(promises)
        .then(() => {
          this.$emit('update:process', updatedProcess);
          if (this.newWarehouseLocationId !== this.orderPart.warehouse_location_id) {
            this.$emit('update:location', this.newWarehouseLocationId);
          }
          if (window.kagneta_attemptedToStartOrderPartProcess) {
            this.$router.push({
              name: 'worker-order-part',
              params: { id: window.kagneta_attemptedToStartOrderPartProcess.order_part_id },
              query: { processId: window.kagneta_attemptedToStartOrderPartProcess.id },
            });
          } else if (updatedProcess.status === 'completed') {
            this.openNextProcess();
          }
          this.$store.commit(CLOSE_DIALOG, 'completeProcessForm');
          eventBus.$emit(OPEN_SNACKBAR, this.$t('order_updated'));
        })
        .catch(err => {
          this.handleProcessSavingError(err);
        });

      this.fetchCurrentUnfinishedProcesses().finally(() => {
        this.isProcessUpdating = false;
      });
    },

    async handleProcessSavingError(err) {
      if (
        err.response.status !== 409 ||
        err.response.data.status !== 'worker_is_not_allowed_to_start_multiple_processes_at_once'
      ) {
        return;
      }

      const unfinishedProcess = err.response.data.data.not_finished_order_part_process;
      const confirmed = await openConfirmDialog({
        title: `${this.$t('unfinished_process_found')} ${unfinishedProcess.process?.title || ''}`,
        body: this.$t('unfinished_process_text'),
        confirmText: this.$t('yes'),
        cancelText: this.$t('no'),
      });

      if (confirmed) {
        window.kagneta_attemptedToStartOrderPartProcess = this.openProcess;
        await this.$router.push({
          name: 'worker-order-part',
          params: { id: unfinishedProcess.order_part_id },
          query: { processId: unfinishedProcess.id },
        });
      }
    },

    removeProcessIdQueryParam() {
      if (!this.$route.query.processId) {
        return;
      }

      const query = clone(this.$route.query);
      delete query.processId;
      this.$router.replace({
        ...this.$route,
        query,
      });
    },
  },
};
</script>
