<template>
  <v-dialog
    :value="$store.getters.isDialogOpen('processProgressForm')"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="600"
    scrollable
    persistent
  >
    <v-form @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          {{ orderPartProcess.process && orderPartProcess.process.title }}
        </v-card-title>
        <v-card-text class="pa-0">
          <v-stepper v-model="activeStep" class="elevation-0" non-linear>
            <v-stepper-header v-show="completingProcess" class="elevation-0">
              <v-stepper-step :step="1" editable @click="activeStep = 1">
                {{ $t('result_of_day') }}
              </v-stepper-step>

              <v-divider />

              <v-stepper-step :step="2" editable>
                {{ $t('process_actions.finish') }}
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content class="pt-0" step="1">
                <v-row>
                  <v-col class="py-2 d-flex align-center justify-center" cols="12">
                    <v-btn large icon @click="previousDay">
                      <v-icon large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <div class="text-center subtitle-1 font-weight-medium" style="min-width: 15em;">
                      {{ displayedDateFormatted }}
                    </div>
                    <v-btn :disabled="!canMoveForward" large icon @click="nextDay">
                      <v-icon large>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="py-2" cols="12">
                    <v-text-field
                      v-model.number="activityFormItem.amount"
                      :error-messages="errors['amount']"
                      :label="
                        formMixin_getRequiredFieldLabel(
                          $t(
                            orderPartProcess.employee_salary_calculation_param === 'minutes'
                              ? 'minutes_spent'
                              : 'units_manufactured',
                          ),
                        )
                      "
                      type="number"
                      @blur="formMixin_clearErrors('amount')"
                    />
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content class="pt-0" step="2">
                <v-row>
                  <v-col class="py-2" cols="12">
                    <v-text-field
                      v-model.number="newQuantity"
                      :error-messages="errors['quantity']"
                      :disabled="orderPartProcess.employee_salary_calculation_param === 'pcs'"
                      :label="formMixin_getRequiredFieldLabel($t('quantity_produced_in_units'))"
                      type="number"
                      @blur="formMixin_clearErrors('quantity')"
                    />
                  </v-col>

                  <v-col class="py-2" cols="12">
                    <v-autocomplete
                      v-model="newWarehouseLocationId"
                      :items="$store.getters.warehouseLocations"
                      :error-messages="errors.warehouse_location_id"
                      :label="$t('warehouse_location')"
                      item-text="name"
                      item-value="id"
                      @blur="formMixin_clearErrors('warehouse_location_id')"
                    />
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col cols="12" sm="6" class="px-4 pb-3 pb-sm-0">
              <v-btn color="primary" block x-large outlined @click.native="close">
                {{ $t('close') }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="px-4">
              <v-btn
                :disabled="isRequestPending"
                :loading="isRequestPending"
                type="submit"
                color="primary"
                block
                x-large
              >
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { addDays, format, isBefore } from 'date-fns';
import { lt } from 'date-fns/locale';
import { CLOSE_DIALOG } from '../store/modules/dialogs';
import formMixin from '../mixins/form-mixin';
import orderPartService from '../api/order-part-service';
import eventBus, { OPEN_SNACKBAR } from '../util/event-bus';
import { mapErrorsToInputs } from '../util/forms';
import clone from 'just-clone';
import { mapActions } from 'vuex';

export default {
  name: 'ProcessProgressFormDialog',

  mixins: [formMixin],

  props: {
    orderPart: {
      type: Object,
      default: () => ({}),
    },

    orderPartProcess: {
      type: Object,
      default: () => ({}),
    },

    completingProcess: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeStep: 1,
      activityData: [],
      isRequestPending: false,
      newQuantity: 0,
      newWarehouseLocationId: null,
      errors: {},
      activityFormItem: {
        date: format(new Date(), 'yyyy-MM-dd'),
      },
    };
  },

  computed: {
    canMoveForward() {
      if (!this.activityFormItem.date) {
        return false;
      }

      return isBefore(addDays(new Date(this.activityFormItem.date), 1), new Date());
    },

    displayedDateFormatted() {
      if (!this.activityFormItem.date) {
        return '';
      }
      return format(new Date(this.activityFormItem.date), 'yyyy-MM-dd (cccc)', {
        locale: lt,
      });
    },

    isTodayFilled() {
      const today = format(new Date(), 'yyyy-MM-dd');
      for (let i = 0; i < this.activityData.length; i++) {
        if (this.activityData[i].date === today) {
          return true;
        }
      }
      return false;
    },
  },

  watch: {
    '$store.state.dialogs.openDialogs': {
      handler(newValue) {
        if (newValue.indexOf('processProgressForm') > -1) {
          this.setActiveStep();
          this.newQuantity = this.orderPartProcess?.quantity || 0;
        } else {
          setTimeout(() => {
            this.activeStep = 1;
          }, 300);
        }
      },
      deep: true,
    },
  },

  created() {
    orderPartService
      .getProcessProgress(this.orderPartProcess.id)
      .then(res => {
        this.activityData = res.data;
      })
      .catch(() => {
        eventBus.$emit(OPEN_SNACKBAR, this.$t('data_fetch_error_try_reloading'));
      })
      .finally(() => {
        this.setOpenDay(format(new Date(), 'yyyy-MM-dd'));
      });
    this.newQuantity = this.orderPartProcess.quantity;
    this.newWarehouseLocationId = this.orderPart.warehouse_location_id;
  },

  methods: {
    ...mapActions('orderPartProcesses', ['fetchCurrentUnfinishedProcesses']),

    nextDay() {
      this.setOpenDay(format(addDays(new Date(this.activityFormItem.date), 1), 'yyyy-MM-dd'));
    },

    previousDay() {
      this.setOpenDay(format(addDays(new Date(this.activityFormItem.date), -1), 'yyyy-MM-dd'));
    },

    setOpenDay(date) {
      for (let i = 0; i < this.activityData.length; i++) {
        if (this.activityData[i].date === date) {
          this.activityFormItem = JSON.parse(JSON.stringify(this.activityData[i]));
          return;
        }
      }
      this.activityFormItem = {
        date,
      };
    },

    setActiveStep() {
      this.activeStep = this.completingProcess && this.isTodayFilled ? 2 : 1;
    },

    close() {
      this.$store.commit(CLOSE_DIALOG, 'processProgressForm');
    },

    onSubmit() {
      if (this.activeStep === 2) {
        this.completeProcess();
      } else {
        this.saveActivityData();
      }
    },

    saveActivityData() {
      this.isRequestPending = true;
      const payload = {
        ...this.activityFormItem,
        user_id: this.$store.getters.currentUser.id,
        unit: this.orderPartProcess.employee_salary_calculation_param,
      };
      orderPartService
        .updateProcessProgress(this.orderPartProcess.id, payload)
        .then(res => {
          if (format(new Date(), 'yyyy-MM-dd') === this.activityFormItem.date) {
            // if updating today = didn't move back or forward days
            if (!this.completingProcess) {
              this.close();
            } else if (format(new Date(), 'yyyy-MM-dd') === this.activityFormItem.date) {
              this.activeStep = 2;
            }
          }

          // if response is empty, it means 0 was entered, activity data was deleted from backend
          if (!res.data?.id) {
            for (let i = 0; i < this.activityData.length; i++) {
              if (this.activityData[i].date === this.activityFormItem.date) {
                const newQuantity = this.orderPartProcess.quantity - this.activityData[i].amount;
                this.$emit('update:process', {
                  ...this.orderPartProcess,
                  quantity: newQuantity,
                });
                this.newQuantity = newQuantity;
                this.$set(this.activityData[i], 'amount', 0);
                break;
              }
            }
            eventBus.$emit(
              OPEN_SNACKBAR,
              `${this.activityFormItem.date} ${this.$t('data_updated').toLowerCase()}`,
            );
            return;
          }

          let found = false;
          for (let i = 0; i < this.activityData.length; i++) {
            if (this.activityData[i].id === res.data.id) {
              found = true;
              this.$set(this.activityData, i, res.data);
              break;
            }
          }
          if (!found) {
            this.activityData.push(res.data);
          }
          if (res.data.order_part_process?.quantity) {
            this.newQuantity = res.data.order_part_process?.quantity;
          }
          eventBus.$emit(
            OPEN_SNACKBAR,
            `${res.data.date} ${this.$t('data_updated').toLowerCase()}`,
          );

          this.$emit('update:process', {
            ...this.orderPartProcess,
            ...res.data?.order_part_process,
          });
        })
        .catch(err => {
          this.errors = mapErrorsToInputs(err);
        })
        .finally(() => {
          this.isRequestPending = false;
        });
    },

    completeProcess() {
      const updatedProcess = {
        ...this.orderPartProcess,
        quantity: this.newQuantity,
        status: 'completed',
      };
      this.saveProcess(updatedProcess);
    },

    // Updates the process with new quantity and status.
    // Also updates orderPart warehouse_location_id if it has changed.
    // (User is prompted to enter it when completing a process)
    async saveProcess(process) {
      let shouldDialogCloseAfterSaving = false;
      let updatedProcess = clone(process);
      this.isRequestPending = true;

      const promises = [
        orderPartService.updateProcess(process).then(res => {
          for (let i = 0; i < res.data.processes.length; i++) {
            const p = res.data.processes[i];
            if (p.id === process.id) {
              updatedProcess = p;
              break;
            }
          }
        }),
      ];
      if (this.newWarehouseLocationId !== this.orderPart.warehouse_location_id) {
        const orderPartPayload = {
          id: this.orderPart.id,
          warehouse_location_id: this.newWarehouseLocationId,
        };
        promises.push(orderPartService.updateLocation(orderPartPayload));
      }

      await Promise.all(promises)
        .then(() => {
          shouldDialogCloseAfterSaving = true;
          this.$emit('update:process', updatedProcess);
          if (this.newWarehouseLocationId !== this.orderPart.warehouse_location_id) {
            this.$emit('update:location', this.newWarehouseLocationId);
          }

          eventBus.$emit(OPEN_SNACKBAR, this.$t('order_updated'));
        })
        .catch(err => {
          if (
            err.response.status === 422 &&
            err.response.data.errors?.status?.includes(
              'validation.can_complete_only_if_previous_processes_already_started',
            )
          ) {
            eventBus.$emit(
              OPEN_SNACKBAR,
              this.$t('can_complete_only_if_previous_processes_already_started'),
            );
            return;
          }
          this.errors = mapErrorsToInputs(err);
        })
        .finally(() => {
          this.isRequestPending = false;
        });

      this.fetchCurrentUnfinishedProcesses().finally(() => {
        if (shouldDialogCloseAfterSaving) {
          this.close();
        }
      });
    },
  },
};
</script>
