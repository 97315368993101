<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ title || defaultTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-textarea
              v-model="machineryComment.text"
              :error-messages="errors['text']"
              :label="$t('text')"
              auto-grow
              @blur="formMixin_clearErrors('text')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="bigButtons">
        <v-row dense>
          <v-col cols="12" sm="6" class="px-4 pb-3 pb-sm-1">
            <v-btn
              color="primary"
              block
              x-large
              outlined
              @click.native="$emit('cancel')"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" class="px-4 pb-3 pb-sm-1">
            <v-btn
              :disabled="isRequestPending"
              :loading="isRequestPending"
              color="primary"
              type="submit"
              block
              x-large
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions v-else>
<!--        <span v-if="!machineryComment.id" class="subtitle-2 ml-3">-->
<!--          * {{ $t('must_be_filled') }}-->
<!--        </span>-->

        <v-spacer/>

        <v-btn
          color="primary"
          text
          @click.native="$emit('cancel')"
        >
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import machineryCommentService from '../../api/machinery-comment-service';

export default {
  name: 'MachineryCommentForm',

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
    title: {
      type: String,
      default: '',
    },
    bigButtons: Boolean,
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      machineryComment: {},
    };
  },

  computed: {
    defaultTitle() {
      return this.$t(this.machineryComment.id ? 'edit_machinery_comment' : 'new_machinery_comment');
    },
  },

  methods: {
    onDialogOpen() {
      this.machineryComment = JSON.parse(JSON.stringify(this.formItem));
      this.errors = {};
    },

    onSubmit() {
      this.crudMixin_createOrUpdate(machineryCommentService, this.machineryComment).then(() => {
        setTimeout(() => {
          this.$emit('reset:title');
        }, 300);
      });
    },
  },
};
</script>
