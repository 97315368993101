<template>
  <v-dialog
    :value="$store.getters.isDialogOpen(dialogName)"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="600"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-progress-circular
          v-if="loadingInventoryItemPurchase"
          color="primary"
          class="mt-4"
          indeterminate
        />

        <template v-else-if="inventoryItemPurchase.id">
          <div class="subtitle-2">
            {{ inventoryItemPurchase.inventory_item.title }}
          </div>

          <table>
            <tr>
              <td class="pr-4">{{ $t('quantity_available_short') }}</td>
              <td class="text-right">
                {{
                  getInventoryItemQuantity(
                    inventoryItemPurchase.quantity_ordered,
                    inventoryItemPurchase.material_unit,
                    inventoryItemPurchase.inventory_item.unit_a_title,
                    inventoryItemPurchase.inventory_item.unit_b_title,
                    inventoryItemPurchase.inventory_item.unit_a_b_ratio,
                  )
                }}
              </td>
            </tr>

            <tr>
              <td class="pr-4">{{ $t('quantity_required_short') }}</td>
              <td class="text-right">{{ requiredQuantity }}</td>
            </tr>
          </table>
        </template>

        <template v-else>
          <!-- for camera preview when scanning using camera -->
          <div id="reader" width="600px" />

          <!-- when scanning using a scanner device -->
          <QrScannerListener
            v-if="isDialogOpen"
            :handled-url-fragments="['inventory-item-purchase-details']"
            @input="onScanSuccess"
          />
        </template>

        <div v-if="qrScanErrorMessage" class="py-4 error--text title">
          {{ qrScanErrorMessage }}
        </div>

        <div v-if="savingErrorMessage" class="pt-4 error--text title">
          <p class="text-preserve-whitespace">{{ savingErrorMessage }}</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row dense>
          <v-col cols="12" sm="6" class="px-3 pb-3 pb-sm-2">
            <v-btn color="primary" block x-large outlined @click.native="close">
              {{ $t('close') }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" class="px-3 pb-3 pb-sm-2">
            <v-btn
              v-if="inventoryItemPurchase.id"
              :disabled="$store.getters.loading['post:api/inventory-item-usages']"
              :loading="$store.getters.loading['post:api/inventory-item-usages']"
              color="primary"
              block
              x-large
              @click.native="save(!!savingErrorMessage)"
            >
              {{ savingErrorMessage ? $t('use_anyway') : $t('use') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Html5Qrcode } from 'html5-qrcode';
import { CLOSE_DIALOG } from '@/store/modules/dialogs';
import inventoryItemPurchaseService from '@/api/inventory-item-purchase-service';
import { getInventoryItemQuantity } from '@/util/inventory-item-quantity';
import inventoryItemUsageService from '@/api/inventory-item-usage-service';
import eventBus, { OPEN_SNACKBAR } from '@/util/event-bus';
import QrScannerListener from '@/components/QrScannerListener';

export default {
  name: 'InventoryItemPurchaseQrCodeScannerDialog',

  components: { QrScannerListener },

  props: {
    orderPart: {
      type: Object,
      default: () => {},
    },
    processId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      dialogName: 'inventoryItemPurchaseQrCodeScanner',
      html5QrCode: null,
      qrScanErrorMessage: '',
      loadingInventoryItemPurchase: false,
      inventoryItemPurchase: {},
      savingErrorMessage: '',
    };
  },

  computed: {
    selectedMaterial() {
      if (!this.orderPart.id || !this.inventoryItemPurchase.id) {
        return {};
      }

      for (let i = 0; i < this.orderPart.materials.length; i++) {
        const { inventory_item_id } = this.orderPart.materials[i];
        if (inventory_item_id === this.inventoryItemPurchase.inventory_item_id) {
          return this.orderPart.materials[i];
        }
      }

      return {};
    },

    requiredQuantity() {
      if (!this.selectedMaterial.id) {
        return '0';
      }

      return getInventoryItemQuantity(
        this.selectedMaterial.material_quantity,
        this.selectedMaterial.material_unit,
        this.selectedMaterial.material.unit_a_title,
        this.selectedMaterial.material.unit_b_title,
        this.selectedMaterial.material.unit_a_b_ratio,
      );
    },

    title() {
      return this.$t(
        this.inventoryItemPurchase.id
          ? 'confirm_inventory_item_usage'
          : 'scan_inventory_item_purchase_qr_code',
      );
    },

    isDialogOpen() {
      return this.$store.state.dialogs.openDialogs.includes(this.dialogName);
    },
  },

  watch: {
    '$store.state.dialogs.openDialogs': {
      async handler(newValue) {
        if (newValue.indexOf(this.dialogName) === -1) {
          return;
        }

        this.qrScanErrorMessage = '';
        this.savingErrorMessage = '';
        this.inventoryItemPurchase = {};
        await this.$nextTick();

        try {
          this.html5QrCode = new Html5Qrcode('reader');
          const config = { fps: 10, qrbox: { width: 250, height: 250 } };
          this.html5QrCode.start(
            { facingMode: 'user' },
            config,
            this.onScanSuccess,
            this.onScanFailure,
          );
        } catch (e) {
          // camera is not working
          // can still scan using scanners
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    getInventoryItemQuantity,

    async onScanSuccess(url) {
      console.log(`Code matched = ${url}`);
      if (!url.includes('inventory-item-purchase-details')) {
        this.qrScanErrorMessage = this.$t('qr_code_has_no_material_data');
        return;
      }

      try {
        this.html5QrCode.stop();
      } catch (e) {
        // wasn't running
      }
      this.qrScanErrorMessage = '';
      const inventoryItemPurchaseId = url.split('/').pop();

      this.loadingInventoryItemPurchase = true;
      const { data } = await inventoryItemPurchaseService.getById(inventoryItemPurchaseId);
      this.inventoryItemPurchase = data;
      this.loadingInventoryItemPurchase = false;
    },

    async save(shouldForceSave = false) {
      this.savingErrorMessage = '';
      const quantity = Math.min(
        +this.inventoryItemPurchase.quantity_ordered,
        +this.selectedMaterial.material_quantity,
      );
      const payload = {
        inventory_item_purchase_id: this.inventoryItemPurchase.id,
        order_part_process_id: this.processId,
        quantity,
        should_force_save: shouldForceSave,
      };

      try {
        await inventoryItemUsageService.create(payload);
        eventBus.$emit(OPEN_SNACKBAR, this.$t('material_marked_for_use'));
        this.$store.commit(CLOSE_DIALOG, this.dialogName);
      } catch (e) {
        if (!e.response?.data?.errors) {
          return;
        }
        const messages = [];
        Object.values(e.response.data.errors).forEach(errors => {
          messages.push(errors.join('\n'));
        });
        this.savingErrorMessage = messages.join('\n');
      }
    },

    close() {
      try {
        if (this.html5QrCode.isScanning) {
          this.html5QrCode.stop();
        }
      } catch (e) {
        // probably wasn't started in the first place, do nothing
      }
      this.$store.commit(CLOSE_DIALOG, this.dialogName);
    },

    onScanFailure(error) {
      // handle scan failure, usually better to ignore and keep scanning.
      // for example:
      console.warn(`Code scan error = ${error}`);
    },
  },
};
</script>
