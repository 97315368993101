<template>
  <div v-if="src">
    <v-row dense>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-btn
          :x-large="largeDownloadButton"
          color="primary"
          class="mb-3"
          block
          @click.stop="download"
        >
          <v-icon left>mdi-download</v-icon>
          {{ $t('download') }}
        </v-btn>
      </v-col>
    </v-row>
    <img v-if="type === 'img'" :src="src" :style="{ 'max-width': imageWidth }" />
    <embed
      v-else-if="type === 'pdf'"
      :src="src"
      type="application/pdf"
      width="100%"
      height="1000"
    />
  </div>
</template>

<script>
import http from '../api/http';
import { downloadFileFromAPI } from '../util/files';

export default {
  name: 'FileViewer',

  props: {
    name: {
      type: String,
      required: true,
    },

    url: {
      type: String,
      required: true,
    },

    largeDownloadButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      src: '',
    };
  },

  created() {
    http({
      url: this.url,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      this.src = URL.createObjectURL(response.data);
    });
  },

  computed: {
    type() {
      let type = '';
      if (/pdf/.test(this.name?.toLowerCase())) {
        type = 'pdf';
      } else if (/jpeg|png|jpg|gif|svg/.test(this.name?.toLowerCase())) {
        type = 'img';
      }
      return type;
    },

    imageWidth() {
      if (this.$vuetify.breakpoint.xsOnly || !this.$store.state.settings.sidenavOpen) {
        return 'calc(100vw - 24px)';
      }
      return 'calc(100vw - 224px)';
    },
  },

  methods: {
    download() {
      downloadFileFromAPI(this.url, this.name);
    },
  },
};
</script>

<style scoped></style>
