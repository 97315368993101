<template>
  <div v-if="orderPart.id" :key="orderPart.id">
    <template v-if="orderPart.id">
      <h1 class="title px-3 pt-3 d-flex align-center">
        <span v-if="orderPart.inventory_item" class="mr-2">{{
          orderPart.inventory_item.title
        }}</span>
      </h1>

      <OrderPartDetails
        :key="orderPartUpdateCounter"
        :part="orderPart"
        :order="orderPart.order"
        class="px-3 pt-3"
        worker-view
      />

      <OrderSummary
        :key="orderPart.order_id"
        :order-part-id="orderPart.id"
        :comment="orderPart.order.comments"
      >
        <template v-slot:prepend>
          <v-col cols="12" md="4">
            <div class="mb-4 mb-md-0">
              <div class="subtitle-2">
                {{ $t('warehouse_location_id') }}
              </div>
              {{ orderPart.warehouse_location ? orderPart.warehouse_location.name : '-' }}
            </div>
          </v-col>
          <v-col cols="12" md="4" class="pr-0 pr-md-4 pb-5">
            <v-btn
              v-if="canEditOpenProcess"
              color="primary"
              block
              large
              @click="$store.commit('openDialog', 'inventoryItemPurchaseQrCodeScanner')"
            >
              <v-icon left>mdi-qrcode</v-icon>
              {{ $t('use_material') }}
            </v-btn>
          </v-col>
          <v-col class="pr-0 pr-md-4 pb-5" cols="12" md="4">
            <v-btn
              color="primary"
              block
              large
              @click="$store.commit('openDialog', 'orderPartWarehouseLocationForm')"
            >
              <v-icon left>mdi-map-marker</v-icon>
              {{ $t('change_warehouse_location') }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div class="mb-4 mb-md-0">
              <div class="subtitle-2">
                {{ $t('responsible_user') }}
              </div>
              {{
                orderPart.order.responsible_user
                  ? orderPart.order.responsible_user.person.full_name
                  : '-'
              }}
            </div>
          </v-col>
        </template>
      </OrderSummary>

      <template v-if="orderPart.processes && orderPart.processes.length">
        <v-divider />
        <WorkerProcesses
          :order-part="orderPart"
          :order="orderPart.order"
          :open-process-id="+$route.query.processId || 0"
          class="mb-12"
          @update:process="processUpdated"
          @update:location="locationUpdated"
          @change:open-process="openProcess = $event"
        />
      </template>

      <template v-if="orderPartDocuments.length">
        <v-divider />
        <OrderPartDocuments :documents="orderPartDocuments" class="mb-4" />
      </template>
    </template>

    <OrderPartWarehouseLocationFormDialog
      :form-item="orderPart"
      @update="$store.commit('orderParts/UPDATE_ORDER_PART', $event)"
    />

    <InventoryItemPurchaseQrCodeScannerDialog
      :order-part="orderPart"
      :process-id="openProcess.id"
    />
  </div>
  <div v-else-if="!loadingOrderPart" class="pa-3">
    <v-alert
      :max-width="$vuetify.breakpoint.lgAndUp ? '35em' : ''"
      color="red"
      class="title text-preserve-whitespace"
      type="info"
      text
    >
      {{ $t('order_part_not_found_in_your_task_list') }}
    </v-alert>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import OrderPartDetails from '../../components/OrderPartDetails';
import WorkerProcesses from '../../components/WorkerProcesses';
import OrderPartDocuments from '../../components/OrderPartDocuments';
import OrderSummary from '@/components/OrderSummary';
import OrderPartWarehouseLocationFormDialog from '@/components/OrderPartWarehouseLocationFormDialog';
import InventoryItemPurchaseQrCodeScannerDialog from '@/components/InventoryItemPurchaseQrCodeScannerDialog';

export default {
  name: 'WorkerOrderPart',

  components: {
    InventoryItemPurchaseQrCodeScannerDialog,
    OrderPartWarehouseLocationFormDialog,
    OrderSummary,
    OrderPartDocuments,
    WorkerProcesses,
    OrderPartDetails,
  },

  data() {
    return {
      openProcess: {},
    };
  },

  computed: {
    ...mapState('orderParts', ['orderParts', 'orderPartUpdateCounter']),
    ...mapGetters(['loading']),

    loadingOrderPart() {
      return (
        this.loading['get:api/order-parts'] ||
        this.loading[`get:api/order-parts/${this.$route.params.id}`]
      );
    },

    canEditOpenProcess() {
      return this.$store.getters.currentUserProcessIds.has(this.openProcess.process_id);
    },

    orderPart() {
      if (!this.$route.params.id || !this.orderParts) {
        return {};
      }
      return this.orderParts.find(part => part.id === +this.$route.params.id) || {};
    },

    orderPartDocuments() {
      return [...(this.orderPart?.documents || []), ...(this.orderPart?.order?.documents || [])];
    },
  },

  methods: {
    processUpdated(process) {
      for (let i = 0; i < this.orderPart.processes.length; i++) {
        if (this.orderPart.processes[i].id === process.id) {
          this.orderPart.processes.splice(i, 1, process);
          break;
        }
      }
    },

    locationUpdated(locationId) {
      for (let i = 0; i < this.$store.getters.warehouseLocations.length; i++) {
        if (this.$store.getters.warehouseLocations[i].id === locationId) {
          this.$set(
            this.orderPart,
            'warehouse_location',
            this.$store.getters.warehouseLocations[i],
          );
          this.orderPart.warehouse_location_id = locationId;
        }
      }
    },
  },
};
</script>
