<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :headers="tableMixin_displayedHeaders"
    :items="rows"
    :mobile-breakpoint="0"
    disable-pagination
    disable-sort
    hide-default-footer
  >
    <template v-if="order" v-slot:item.order_part_no="{ item }">
      {{ order.order_no }}.{{ item.order_part_no }}
    </template>

    <template v-slot:item.status="{ item }">
      <v-chip :color="orderPartStatusMap[item.status].color" class="caption" label small>
        {{ orderPartStatusMap[item.status].text }}
      </v-chip>
    </template>

    <template v-slot:expanded-item="{ item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.status>
          <v-chip :color="orderPartStatusMap[item.status].color" class="caption" label small>
            {{ orderPartStatusMap[item.status].text }}
          </v-chip>
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import { arrayToMap } from '@/util/array';
import { ORDER_PART_STATUSES } from '@/api/order-part-service';
import tableMixin from '@/mixins/table-mixin';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';

export default {
  name: 'OrderPartSimpleTable',

  components: { BaseExpandedTableRow },

  mixins: [tableMixin],

  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    order: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('number'),
          value: 'order_part_no',
        },
        {
          text: this.$t('quantity'),
          value: 'quantity',
          align: 'end',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('status'),
          value: 'status',
          align: 'end',
          hidden: 'xsOnly',
        },
      ];
    },

    orderPartStatusMap() {
      return arrayToMap(ORDER_PART_STATUSES);
    },
  },
};
</script>
