import http from './http';

const endpoint = 'api/machinery-comments';

const machineryCommentService = {
  model: 'machineryComment',

  getPage: (page, params) => http.get(`${endpoint}?page=${page}`, { params }),

  search: query => http.get(`${endpoint}/find/${query}`),

  create: machineryComment => http.post(`${endpoint}`, machineryComment),

  update: machineryComment => http.put(`${endpoint}/${machineryComment.id}`, machineryComment),

  delete: machineryComment => http.delete(`${endpoint}/${machineryComment.id}`),
};

export default machineryCommentService;
