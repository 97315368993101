<template>
  <div class="my-3">
    <div v-if="!hideTitle" class="my-3 d-flex justify-space-between align-center">
      <div>
        <h3 class="subtitle-2">
          {{ $t('machinery_comments') }}
        </h3>
      </div>
      <v-btn color="primary" @click="$emit('new-item')">
        <v-icon left>mdi-plus</v-icon>
        {{ $t('create_machinery_comment') }}
      </v-btn>
    </div>

    <div v-if="isDataLoading" class="pb-3">
      <v-progress-circular color="primary" indeterminate />
    </div>

    <div v-else-if="!machineryCommentArray.length" class="grey--text">{{ $t('no_comments') }}.</div>

    <div v-for="comment in machineryCommentArray" :key="comment.id">
      <div class="d-flex align-center">
        <div class="subtitle-2 black--text">
          {{ comment.creator && comment.creator.person.full_name }}
        </div>
        <div class="grey--text body-2 pl-1">
          {{ getTimeFromDate(comment.created_at) }}
        </div>
      </div>
      <p class="text-preserve-whitespace black--text">{{ comment.text }}</p>
      <div>
        <BaseActionMenu :actions="actions" :item="comment" />
      </div>
    </div>

    <v-btn
      v-if="hasMorePages"
      :disabled="isNextPageLoading"
      :loading="isNextPageLoading"
      color="primary"
      text
      @click="getMachineryComments(machineryCommentPagination.page + 1)"
    >
      {{ $t('show_more') }}
    </v-btn>
  </div>
</template>

<script>
import BaseActionMenu from './base/BaseActionMenu';
import { getTimeFromDate } from '../util/date';
import crudMixin from '../mixins/crud-mixin';
import machineryCommentService from '../api/machinery-comment-service';

export default {
  name: 'MachineryCommentList',

  components: { BaseActionMenu },

  mixins: [crudMixin],

  props: {
    machineryId: {
      type: Number,
      required: true,
    },
    hideTitle: Boolean,
    refreshCount: Number,
    createdComment: Object,
  },

  data() {
    return {
      isDataLoading: true,
      isNextPageLoading: false,
      machineryCommentArray: [],
      machineryCommentPagination: {},
      getTimeFromDate,
      actions: [
        // {
        //   callback: p => this.$emit('edit', p),
        //   label: this.$t('edit'),
        //   icon: 'mdi-pencil',
        // },
        // {
        //   callback: p => this.$emit('delete', p),
        //   label: this.$t('delete'),
        //   icon: 'mdi-delete',
        // },
      ],
    };
  },

  computed: {
    hasMorePages() {
      const { page, rowsPerPage, total } = this.machineryCommentPagination;
      return rowsPerPage * page < total;
    },
  },

  watch: {
    refreshCount() {
      this.getMachineryComments(this.machineryCommentPagination.page, false);
    },

    createdComment(value) {
      this.machineryCommentArray.unshift(value);
      this.machineryCommentPagination.total += 1;
    },
  },

  created() {
    this.getMachineryComments();
  },

  methods: {
    getMachineryComments(page = 1, shouldAppend = true) {
      const params = {
        machinery_id: this.machineryId,
      };
      this.isNextPageLoading = true;
      this.crudMixin_getPage(
        machineryCommentService.getPage,
        'machineryComment',
        page,
        params,
        shouldAppend,
      ).finally(() => {
        this.isNextPageLoading = false;
      });
    },
  },
};
</script>

<style scoped></style>
