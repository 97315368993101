<template>
  <v-row class="px-3 mb-3" no-gutters>
    <slot name="prepend" />
    <v-col cols="12" md="4"> </v-col>
    <v-col class="pr-0 pr-md-4 text-right" cols="12" md="4">
      <v-btn color="primary" block large @click="openDialog">
        <v-icon left>mdi-file-document</v-icon>
        {{ $t('order_summary') }}
      </v-btn>
    </v-col>

    <v-dialog
      :value="$store.getters.isDialogOpen('orderSummary')"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="900"
      transition="slide-y-reverse-transition"
      scrollable
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title>
          {{ $t('order_summary') }}
        </v-card-title>

        <v-card-text v-if="!summary" class="text-center">
          <v-progress-circular color="primary" indeterminate />
        </v-card-text>
        <v-card-text v-else>
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <div class="subtitle-2">
                {{ $t('title') }}
              </div>
              {{ summary.title || '-' }}
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <div class="subtitle-2">
                {{ $t('order_no') }}
              </div>
              {{ summary.order_no || '-' }}
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <div class="subtitle-2">
                {{ $t('quantity') }}
              </div>
              {{ summary.quantity || '-' }}
            </v-col>

            <v-col v-if="summary.comments" cols="12">
              <div class="subtitle-2">
                {{ $t('comments') }}
              </div>
              <div class="text-preserve-whitespace">{{ comment }}</div>

            </v-col>

            <v-col cols="12">
              <div class="subtitle-2 pt-3">
                {{ $t('order_parts') }}
              </div>

              <OrderPartSimpleTable :rows="summary.parts" :order="summary" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row dense>
            <v-col cols="12" sm="6" />
            <v-col cols="12" sm="6" class="px-4">
              <v-btn color="primary" block x-large outlined @click="closeDialog">
                {{ $t('close') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import orderService from '@/api/order-service';
import { CLOSE_DIALOG, OPEN_DIALOG } from '@/store/modules/dialogs';
import OrderPartSimpleTable from '@/components/tables/OrderPartSimpleTable';

export default {
  name: 'OrderSummary',

  components: { OrderPartSimpleTable },

  props: {
    orderPartId: {
      type: Number,
      required: true,
    },
    comment: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      summary: null,
    };
  },

  methods: {
    async fetchOrderSummary() {
      const { data } = await orderService.getSummary(this.orderPartId);
      this.summary = data;
    },

    openDialog() {
      this.$store.commit(OPEN_DIALOG, 'orderSummary');
      if (!this.summary) {
        this.fetchOrderSummary();
      }
    },

    closeDialog() {
      this.$store.commit(CLOSE_DIALOG, 'orderSummary');
    },
  },
};
</script>
