<template>
  <div>
    <div v-for="(machinery, index) in machineryArray" :key="machinery.id">
      <v-divider v-if="index > 0" />

      <v-row class="px-3" no-gutters>
        <v-col class="py-2" cols="12" sm="6" md="4">
          <div class="subtitle-2">
            {{ $t('machinery') }}
          </div>
          <div v-if="machinery" class="black--text">
            {{ machinery.name || '-' }}
          </div>
        </v-col>
        <v-col class="py-2" v-if="machinery.id" cols="12" sm="6" md="4">
          <div class="subtitle-2">
            {{ $t('machinery_status') }}
          </div>
          <v-chip
            :color="machineryStatusMap[machinery.status].color"
            class="persist-opacity font-weight-medium"
            label
            small
          >
            {{ $t(`machinery_statuses.${machinery.status}`) }}
          </v-chip>
        </v-col>
        <v-col class="d-flex justify-end py-2 pr-0 pr-md-4" cols="12" md="4">
          <v-menu v-if="canEdit && machinery.id" bottom offset-y>
            <template #activator="{ on }">
              <v-btn v-on="on" color="primary" block large>
                {{ $t('change_machinery_status') }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="status in machineryStatuses"
                :key="status.value"
                @click="updateMachineryStatus(machinery, status.value)"
              >
                <v-list-item-title>
                  {{ $t(status.text) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>

        <template v-if="canEdit">
          <v-col class="py-2" cols="12" md="4">
            <template
              v-if="
                process.employee_salary_amount_per_unit && process.employee_salary_calculation_param
              "
            >
              <div class="subtitle-2">
                {{
                  process.employee_salary_calculation_param === 'minutes'
                    ? $t('salary_per_minute')
                    : $t('salary_per_unit')
                }}
              </div>
              <div class="black--text">
                {{ process.employee_salary_amount_per_unit | currency }}
              </div>
            </template>
          </v-col>
          <v-col class="py-2" cols="12" md="4" />
          <v-col cols="12" md="4" class="d-flex justify-end py-2 pr-0 pr-md-4">
            <v-btn color="primary" block large @click="openMachineryComments(machinery)">
              {{ $t('machinery_comments_full') }}
            </v-btn>
          </v-col>
        </template>
      </v-row>
    </div>

    <v-dialog
      :value="$store.getters.isDialogOpen('machineryCommentForm')"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="700"
      persistent
      scrollable
    >
      <MachineryCommentForm
        :dialog="$store.getters.isDialogOpen('machineryCommentForm')"
        :form-item="machineryCommentFormItem"
        :title="machineryCommentFormTitle"
        big-buttons
        @create="createdComment = $event"
        @cancel="closeMachineryCommentForm"
        @reset:title="machineryCommentFormTitle = ''"
      />
    </v-dialog>

    <v-dialog
      :value="$store.getters.isDialogOpen('machineryCommentList')"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      scrollable
      @input="$store.commit('closeDialog', 'machineryCommentList')"
    >
      <v-card>
        <v-card-title>
          {{ commentListDialogTitle }}
        </v-card-title>
        <v-card-text>
          <MachineryCommentList
            :key="selectedMachinery.id"
            :machinery-id="selectedMachinery.id"
            :created-comment="createdComment"
            hide-title
          />
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col cols="12" sm="6" class="px-4 pb-3 pb-sm-1">
              <v-btn
                color="primary"
                block
                x-large
                outlined
                @click="$store.commit('closeDialog', 'machineryCommentList')"
              >
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="px-4 pb-3 pb-sm-1">
              <v-btn
                :disabled="isRequestPending"
                :loading="isRequestPending"
                color="primary"
                block
                x-large
                @click="$store.commit('openDialog', 'machineryCommentForm')"
              >
                {{ $t('create_machinery_comment') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import machineryService, {
  getMachineryStatusMap,
  MACHINERY_STATUSES,
} from '../api/machinery-service';
import eventBus, { OPEN_SNACKBAR } from '../util/event-bus';
import MachineryCommentForm from './forms/MachineryCommentForm';
import MachineryCommentList from './MachineryCommentList';
import { CLOSE_DIALOG } from '../store/modules/dialogs';
import { updateItemById } from '@/util/array';

export default {
  name: 'WorkerProcessMachinery',

  components: { MachineryCommentList, MachineryCommentForm },

  props: {
    process: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },

  data() {
    return {
      isRequestPending: false,
      createdComment: {},
      selectedMachinery: {},
      machineryCommentFormItem: {},
      machineryCommentFormTitle: '',
      machineryStatuses: MACHINERY_STATUSES,
      machineryStatusMap: getMachineryStatusMap(),
    };
  },

  computed: {
    machineryArray() {
      return this.process.machinery || [];
    },

    commentListDialogTitle() {
      return `${this.$t('machinery_genitive')} ${this.selectedMachinery.name} ${this.$t(
        'comments',
      ).toLowerCase()}`;
    },
  },

  created() {
    if (!this.process.machinery) {
      const params = {
        process_id: this.process.process_id,
      };
      machineryService.getPage(1, params).then(res => {
        this.$set(this.process, 'machinery', res.data?.data || []);
      });
    }
  },

  methods: {
    closeMachineryCommentForm() {
      this.$store.commit(CLOSE_DIALOG, 'machineryCommentForm');
      this.machineryCommentFormTitle = '';
    },

    updateMachineryStatus(machinery, newStatus) {
      this.machineryCommentFormItem.machinery_id = machinery.id;
      if (newStatus === machinery.status) {
        return;
      }
      const updatedMachinery = {
        ...machinery,
        status: newStatus,
      };
      machineryService.updateStatus(updatedMachinery).then(res => {
        this.$set(this.process, 'machinery', updateItemById(this.process.machinery, res.data));
        eventBus.$emit(OPEN_SNACKBAR, this.$t('machinery_updated'));
        if (res.data.status === 'malfunction') {
          this.machineryCommentFormTitle = `${this.$t('describe')} ${res.data.name}
          ${this.$t('malfunction_accusative').toLowerCase()}`;
          this.$store.commit('openDialog', 'machineryCommentForm');
        }
      });
    },

    openMachineryComments(machinery) {
      this.selectedMachinery = machinery;
      this.machineryCommentFormItem.machinery_id = machinery.id;
      this.$store.commit('openDialog', 'machineryCommentList');
    },
  },
};
</script>

<style scoped></style>
