<template>
  <v-dialog
    :value="$store.getters.isDialogOpen(dialogName)"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="600"
    scrollable
    persistent
  >
    <v-form @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          {{ $t('change_warehouse_location') }}
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                v-model="orderPart.warehouse_location_id"
                :items="$store.getters.warehouseLocations"
                :error-messages="errors.warehouse_location_id"
                :label="$t('new_warehouse_location')"
                item-text="name"
                item-value="id"
                @input="formMixin_clearErrors('warehouse_location_id')"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col cols="12" sm="6" class="px-4 pb-3 pb-sm-0">
              <v-btn color="primary" block x-large outlined @click.native="close">
                {{ $t('close') }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="px-4">
              <v-btn
                :disabled="$store.getters.loading[`patch:api/order-parts/${orderPart.id}`]"
                :loading="$store.getters.loading[`patch:api/order-parts/${orderPart.id}`]"
                type="submit"
                color="primary"
                block
                x-large
              >
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { CLOSE_DIALOG } from '@/store/modules/dialogs';
import formMixin from '../mixins/form-mixin';
import orderPartService from '../api/order-part-service';
import crudMixin from '@/mixins/crud-mixin';
import { mapErrorsToInputs } from '@/util/forms';
import eventBus, { OPEN_SNACKBAR } from '@/util/event-bus';

export default {
  name: 'OrderPartWarehouseLocationFormDialog',

  mixins: [formMixin, crudMixin],

  props: {
    formItem: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      errors: {},
      orderPart: {},
      dialogName: 'orderPartWarehouseLocationForm',
    };
  },

  watch: {
    '$store.state.dialogs.openDialogs': {
      handler(newValue) {
        if (newValue.indexOf(this.dialogName) === -1) {
          return;
        }
        this.orderPart = {
          id: this.formItem.id,
          warehouse_location_id: null,
        };
      },
      deep: true,
    },
  },

  created() {
    this.newWarehouseLocationId = this.orderPart.warehouse_location_id;
  },

  methods: {
    close() {
      this.$store.commit(CLOSE_DIALOG, this.dialogName);
    },

    onSubmit() {
      orderPartService
        .updateLocation(this.orderPart)
        .then(() => {
          // patch method returns empty response
          this.$emit('update', {
            ...this.formItem,
            ...this.orderPart,
            warehouse_location: this.$store.getters.warehouseLocations.find(
              l => l.id === this.orderPart.warehouse_location_id,
            ),
          });
          eventBus.$emit(OPEN_SNACKBAR, this.$t('order_part_warehouse_location_updated'));
          this.close();
        })
        .catch(err => {
          this.errors = mapErrorsToInputs(err);
        });
    },
  },
};
</script>
