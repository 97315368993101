<template>
  <v-tabs show-arrows grow>
    <v-tab
      v-for="d in documents"
      class="px-3 border-b"
      :key="d.id"
    >
      {{ d.file_name }}
    </v-tab>

    <v-tab-item
      v-for="d in documents"
      class="pa-3"
      transition="none"
      reverse-transition="none"
      :key="d.id"
    >
      <FileViewer
        :name="d.file_name"
        :url="d.file_url"
        large-download-button
      />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import FileViewer from './FileViewer';

export default {
  name: 'OrderPartDocuments',

  components: { FileViewer },

  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
